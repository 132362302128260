/* @import url("https://fonts.googleapis.com/css?family=Montserrat:900");
body {
  background-color: #ffff00;
  background-image: radial-gradient(#000000 1px, #f8ff00 1px);
  background-size: 50px 50px;
}

.page-title {
  font-family: Montserrat;
  font-size: 4vw;
  margin-left: 1vw;
  padding-top: 1vw;
}

* {
  padding: 0;
  margin: 0;
}

.project-library {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 30vw));
  grid-gap: 1vw;
  margin-left: 2vw;
}

.user-info {
  margin-top: 2vw;
  margin-left: 2vw;
  margin-bottom: 1vw;
  background-color: #ffffff;
  border-radius: 0.3rem;
  box-shadow: 0 0 0 0.13vw #000000;
  font-weight: 600;
  transition: color 0.2s ease-out, border-radius 0.2s ease-out,
    background-color 0.2s ease-out;
  border-style: solid;
  border: solid;
  max-width: 45%;
}

.user-info:hover {
  border-radius: 1.2rem;
  background-color: #000000;
  color: #f8ff00;
}

.what-I-do {
  margin-left: 1vw;
}

.about-me {
  margin-left: 1vw;
  margin-top: 0.5vw;
  padding-bottom: 1vw;
} */

.App {
  padding: 3rem;
}
